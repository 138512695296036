@charset "UTF-8";

/* vendor */
@import "~bootstrap/scss/bootstrap";
@import "~toastr/toastr";
@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "calc";

.invalid-feedback {
    display: block;
}

h1,h2,h3,h4,h5, label, table {
    color: #404040!important;
}

header {
    nav {
        border-radius: 20px;
        background: -moz-linear-gradient(180deg,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
        background: -webkit-gradient(linear,left top,right top,color-stop(0%,rgba(50,200,250,1)),color-stop(100%,rgba(188,125,228,1)));
        background: -webkit-linear-gradient(
                180deg
            ,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
        background: -o-linear-gradient(180deg,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
        background: -ms-linear-gradient(180deg,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
        background: linear-gradient(
                270deg
            ,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18a3eb', endColorstr='#18a3eb',GradientType=1 );

        .navbar-brand {
            line-height: 15px;
            img {
                max-width: 150px;
                filter: brightness(0) invert(1);
            }

            small {
                font-size: 12px;
                color: #ffffff;
            }
        }

        .navbar-nav {
            .nav-item {
                color:#fff;
                .nav-link {
                    color:#fff;
                    &:hover {
                        color: #eeeeee;
                    }
                }
            }
        }
    }
}

body {
    background: #f9f9f9;
}

.breadcrumbs {
    font-size: 14px;
    font-weight: 500;
    color: #828282;

    ul {
        list-style: none;

        li {
            position: relative;
            padding-right: 10px;
            margin-right: 5px;
            color: #18a3eb;

            a {
                color: #828282;
                &:after {
                    content: "/";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 6px;
                    height: 100%;
                    color: #828282;
                }
            }
        }
    }
}

.profile {
    position: relative;
    padding: 32px;
    border-radius: 20px;
    border: 2px solid #18a3eb;

    h2 {
        position: absolute;
        top: -24px;
        left: -2px;
        height: 48px;
        padding-right: 16px;
        background: #f9f9f9;
        font-size: 20px;
        font-weight: 600;
        color: #404040;
    }

    ul {
        list-style: none;

        li {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #828282;

            span {
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
}

.last_orders {
    position: relative;
    padding: 32px;
    border-radius: 20px;
    border: 2px solid #eeeeee;

    h2 {
        position: absolute;
        top: -24px;
        left: -2px;
        height: 48px;
        padding-right: 16px;
        background: #f9f9f9;
        font-size: 20px;
        font-weight: 600;
        color: #404040;
    }

    ul {
        list-style: none;

        li {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500;
            color: #828282;

            span {
                display: inline-block;
                margin-left: 5px;
            }
        }
    }
}

.button {
    padding: 10px 38px 8px;
    border: 0;
    border-bottom: 3px solid #18a3eb;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    outline: none;
    background: #f0f4f8;
    text-decoration: none!important;
    cursor: pointer;
    color:#fff;

    &:hover {
        color:#eeeeee;
    }
}

.button-sm {
    padding: 5px 28px 4px;
    font-size: 12px;
}

.button-big {
    font-size: 18px;
}

.gradient-bg {
    border-color: transparent;
    background: -moz-linear-gradient(180deg,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
    background: -webkit-gradient(linear,left top,right top,color-stop(0%,rgba(50,200,250,1)),color-stop(100%,rgba(88,125,228,1)));
    background: -webkit-linear-gradient(
            180deg
        ,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
    background: -o-linear-gradient(180deg,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
    background: -ms-linear-gradient(180deg,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
    background: linear-gradient(
            270deg
        ,rgba(50,200,250,1) 0%,rgba(88,125,228,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#18a3eb', endColorstr='#587de4',GradientType=1 );
}

/*.calc-info__box {
    padding-left: 40px;
    padding-right: 40px;

    .calc-info__item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .calc-info-img {
            width: 236px;
            position: relative;

            img {
                display: block;
                max-width: 100%;
                border: 0;
            }
        }

        .calc-info-desc__jaw {
            position: relative;
            padding-left: 83px;
            font-size: 15px;
            line-height: 1.26667;
            font-weight: 500;
            min-height: 75px;
        }
    }
}*/

.form-control-sm {
    //font-size: 12px;
}

table {
    th,td {
        vertical-align: middle;
    }
}

.selected_teeth_table {
    border-radius: 0.2rem;
    font-size: 16px;
    margin: 0 auto;
    float: none;

    th,td {
        vertical-align: middle;
    }

    thead {
        th {
            font-weight: 400;
            padding-bottom: .8rem;
        }
    }

    tbody {
        td.tooth_title {
            font-weight: 800;
        }
    }

    .remove_tooth {
        border-radius: 100%;
        border: 0;
        font-size: 14px;
        background: #ff5c5c;
        color: #fff;
        width: 26px;
        height: 25px;
        text-align: center;
        line-height: 24px;
        padding: 0;

        i {
            margin: 0;
            font-weight: normal;
        }
    }
}

/*@media (max-width: 991px) {
    .selected_teeth_table {
        font-size: 70%;
    }
}*/

.was-validated .form-control:invalid, .form-control.is-invalid {
    background-position: right calc(0.375em + 0.5875rem) center;
}

.testimonial-slider-wrap-container {
    padding: 1px;
    border-radius: 20px;
}

.testimonial-slider-wrap {
    position: relative;
    padding: 30px 30px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 25px 20px 27px rgb(0 0 0 / 10%);
}

.testimonial-bg-shape {
    position: relative;
}

.testimonial-bg-shape::before {
    content: '';
    position: absolute;
    bottom: -90px;
    left: -130px;
    z-index: -1;
    width: 499px;
    height: 492px;
    background: url('/images/testimonial-content-bg.png') no-repeat;
}

.amount {
    h5 {
        color: #7dc519!important;
    }
}

#create_order {


}

.custom-file {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.5;
    border: none;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.5rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    padding: 0.250rem 0.75rem;
    overflow: hidden;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 0;

    &:after {
        content: "\41F\440\43E\432\43E\434\43D\438\43A" !important;
        cursor: pointer;
        padding: 0.250rem 0.75rem;
    }
}

.muted {
    opacity: .4;
}

label.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

a {
    color: #18a3eb;
    transition: .3s ease;

    &:hover {
        color: #3c83a9;
    }
}

.dropdown-menu {
    padding: 0;

    a {
        display: block;
        width: 100%;
        padding: 10px 10px;
        clear: both;
        font-weight: 400;
        font-size: 14px;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;

        &:hover, &:focus {
            color: #000;
        }
    }
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-light .navbar-toggler {
    color: #fff;
    border-color: #a6e2fc;
}

.navbar-light .navbar-toggler-icon {
    filter: brightness(0) invert(1);
}

.pagination {
    .page-link {
        color: #18a3eb;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: #18a3eb;
        border-color: #18a3eb;
    }
}
